import gql from 'graphql-tag';
import { getBasketFragment } from '@queries/fragments';

export const GET_BASKET = gql`
  query basket_getBasket($basketId: ID) {
    basket_getBasket(basketId: $basketId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_ADD_ITEM = gql`
  mutation basket_addItem($basketId: ID, $sku: ID!, $quantity: Int!, $freeTrial: Boolean) {
    basket_addItem(basketId: $basketId, sku: $sku, quantity: $quantity, freeTrial: $freeTrial) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_REMOVE_ITEM = gql`
  mutation basket_removeItem($basketId: ID, $itemId: ID!) {
    basket_removeItem(basketId: $basketId, itemId: $itemId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_UPSELL_ITEM = gql`
  mutation basket_upsellItem($basketId: ID, $itemId: Int!, $toSku: String!) {
    basket_upsellItem(basketId: $basketId, itemId: $itemId, toSku: $toSku) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_ADD_COUPON = gql`
  mutation basket_addCoupon($basketId: ID, $couponCode: String!) {
    basket_addCoupon(basketId: $basketId, couponCode: $couponCode) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_REMOVE_COUPON = gql`
  mutation basket_removeCoupon($basketId: ID) {
    basket_removeCoupon(basketId: $basketId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_SET_ITEM_QTY = gql`
  mutation basket_setItemQuantity($itemId: ID!, $quantity: Int!, $basketId: ID) {
    basket_setItemQuantity(itemId: $itemId, quantity: $quantity, basketId: $basketId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_ORDER = gql`
  mutation basket_order($basketId: ID, $leadAttribution: [KeyValueInput]) {
    basket_order(basketId: $basketId, leadAttribution: $leadAttribution) {
      id
      orderNumber
      date
      type
      status
      statusLabel
      itemCount
      currency
      subTotal
      discountAmount
      discountCode
      shipping
      total
      awinParts
      awinTotal
    }
  }
`;

export const BASKET_MERGE_GUEST = gql`
  mutation basket_mergeGuestBasket($basketId: ID!) {
    basket_mergeGuestBasket(basketId: $basketId)
  }
`;

export const BASKET_APPLY_SHIPPING_ADDRESS = gql`
  mutation basket_applyShippingAddress(
    $basketId: ID
    $firstName: String
    $lastName: String
    $street: String
    $city: String
    $region: String
    $postcode: String
    $countryId: String
    $phone: String
  ) {
    basket_applyShippingAddress(
      basketId: $basketId
      firstName: $firstName
      lastName: $lastName
      street: $street
      city: $city
      region: $region
      postcode: $postcode
      countryId: $countryId
      phone: $phone
    ) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_SET_PAYMENT_METHOD = gql`
  mutation basket_setPaymentMethod($basketId: ID, $methodId: ID!) {
    basket_setPaymentMethod(basketId: $basketId, methodId: $methodId) {
      ...getBasket
    }
  }
  ${getBasketFragment}
`;

export const BASKET_REMOVE_PAYMENT_INTENT = gql`
  mutation basket_removePaymentIntent($basketId: ID) {
    basket_removePaymentIntent(basketId: $basketId)
  }
`;

export const BASKET_CHECK_PRODUCT_RESTRICTIONS = gql`
  query basket_checkProductRestrictions($basketId: ID!) {
    basket_checkProductRestrictions(basketId: $basketId) {
      hasRestrictedItems
      restrictedItems
    }
  }
`;

export const BASKET_ITEM_COUNT = gql`
  query basket_itemCount($basketId: ID) {
    basket_itemCount(basketId: $basketId)
  }
`;
