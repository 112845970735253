import { isSSR } from '@services/global';
import { IGAUserData } from '@src/types/tracking';
import { IBasketItem } from '../../types/basket';

enum IUAEventCategory {
  ecommerce = 'ecommerce',
  engagement = 'engagement',
}

interface IGA4EventItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  price?: number;
  quantity?: number;
}

interface IGA4EventParams {
  currency?: string;
  value?: number;
  coupon?: string;
  payment_type?: string;
  items?: IGA4EventItem[];
  method?: string;
}

export const trackGAEvent = (name: string, data: any, groups?: string | string[]) => {
  if (!isSSR && window.gtag) {
    const { eventName, category } = getGAEventNameAndCategory(name);
    const mappedData = getMappedDataForGAEvent(eventName, data);
    window.gtag('event', eventName, {
      send_to: groups || 'analytics', // analytics will send events to all ua and ga4 configs in gtag
      ...mappedData,
    });
  }
};

export const setUserDataToGtag = (userData: IGAUserData) => {
  if (!isSSR && window.gtag) {
    window.gtag('set', 'user_data', {
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      phone_number: userData.phone,
    });
  }
};

const trackGA4Event = (data: any) => {
  if (!isSSR && window.gtag) {
    const { event_category, event_label, value, event_action } = data;
    const { eventName, category } = getGAEventNameAndCategory(event_action);
    window.gtag('event', event_action, {
      send_to: 'ga4',
    });
  }
};

const trackUAEvent = (data: any) => {
  if (!isSSR && window.gtag) {
    const { event_category, event_label, value, event_action } = data;
    const { eventName, category } = getGAEventNameAndCategory(event_action);
    window.gtag('event', event_action, {
      send_to: 'ua',
    });
  }
};

export const getGAEventNameAndCategory = (
  name: string
): { eventName: string; category: IUAEventCategory } => {
  switch (name) {
    case 'AddToCart':
      return { eventName: 'add_to_cart', category: IUAEventCategory.ecommerce };
    case 'RemoveFromCart':
      return { eventName: 'remove_from_cart', category: IUAEventCategory.ecommerce };
    case 'Social Share':
      // what to do with Click CTA event
      return { eventName: 'share', category: IUAEventCategory.engagement };
    case 'Checkout Started':
      return { eventName: 'begin_checkout', category: IUAEventCategory.ecommerce };
    case 'Product List Viewed':
      return { eventName: 'view_item_list', category: IUAEventCategory.engagement };
    case 'Purchase':
    case 'CompletePayment':
    case 'Checkout':
      return { eventName: 'purchase', category: IUAEventCategory.ecommerce };
    case 'Product Viewed':
      return { eventName: 'view_item', category: IUAEventCategory.engagement };
    case 'Search Button Clicked':
      return { eventName: 'search', category: IUAEventCategory.engagement };
    case 'Basket Viewed':
      return { eventName: 'view_cart', category: IUAEventCategory.ecommerce };
    case 'Finished consultation':
      return { eventName: 'finished_consultation', category: IUAEventCategory.engagement };
    case 'Started consultation':
      return { eventName: 'started_consultation', category: IUAEventCategory.engagement };
    case 'Closed consultation':
      return { eventName: 'closed_consultation', category: IUAEventCategory.engagement };
    default:
      return { eventName: name, category: IUAEventCategory.engagement };
  }
};

const getMappedDataForGAEvent = (name: string, data: any) => {
  switch (name) {
    case 'add_to_cart':
    case 'FE AddToBasket':
    case 'remove_from_cart':
      return {
        currency: data.currency,
        value: data.value,
        items: mapCartItemsForGAAddOrRemoveEvent(data.items),
      };
    case 'share':
      return {
        method: data.social_media,
        item_id: data.content_name,
      };
    case 'begin_checkout':
      return {
        currency: data.currency,
        value: data.value,
        coupon: data.coupon || '',
        items: mapCartItemsForGAPurchaseEvent(data.items),
      };
    case 'purchase':
    case 'FE Order Completed':
      return {
        currency: data.currency,
        value: data.value,
        transaction_id: data.order_id || '',
        coupon: data.coupon || '',
        shipping: data.shipping || 0,
        items: mapCartItemsForGAPurchaseEvent(data.items),
      };
    default:
      return data;
  }
};

const mapCartItemsForGAPurchaseEvent = (items: IBasketItem[]) =>
  items.map(item => ({
    item_id: item.sku,
    item_name: item.name,
    quantity: item.qty,
    price: item.rowTotalWithDiscount,
    discount: item.rowTotal - item.rowTotalWithDiscount,
  }));

const mapCartItemsForGAAddOrRemoveEvent = (items: IBasketItem[]) =>
  items.map(item => {
    const { sku, name, qty, rowTotal, rowTotalWithDiscount } = item;
    const discount = rowTotal < rowTotalWithDiscount ? 0 : rowTotal - rowTotalWithDiscount;
    return {
      item_id: sku,
      item_name: name,
      quantity: qty,
      price: getPriceForAddOrRemoveItem(item),
      discount,
    };
  });

export const getPriceForAddOrRemoveItem = (item: IBasketItem | null) => {
  if (!item) {
    return 0;
  }
  const { rowTotal, rowTotalWithDiscount, qty } = item;
  if (qty > 1) {
    // when removing items with quantity > 1
    return rowTotalWithDiscount;
  }
  return rowTotal > rowTotalWithDiscount ? rowTotalWithDiscount : rowTotal;
};

export const addEmailDataToGTM = (email: string) => {
  if (!isSSR && window.dataLayer) {
    window.dataLayer.push({ event: 'email', userEmail: email });
  }
};
