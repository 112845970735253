import { formatStringToBoolean } from '../format';
import {
  IFacebookData,
  IPinterestTrackingData,
  IPromotionTracking,
  IRedditTrackingData,
  ITiktokTrackingData,
} from '@interfaces/tracking';
import { getSessionId } from '../session';
import { isSSR } from '../global';
import { trackGAEvent } from './ga';
import { CUSTOM_TRACKING_EVENTS } from '@constants/enums';
import { getCookie } from '@services/cookies';
import { rudderstackIdentify, rudderstackPageEvent, sendEventToRudderstack } from './rudderstack';
import { IGAUserData } from '@src/types/tracking';
import { setUserDataToGtag } from '@src/services/tracking/ga';
import { v4 as uuidv4 } from 'uuid';

declare global {
  interface Window {
    AWIN: any;
    fbq: any;
    ttq: any;
    pintrk: any;
    gtag: any;
    adalyserTracker: any;
    rdt: any;
    heap: any;
    GlobalAdalyserNamespace: any;
    VWO: any;
  }
}

interface IUtmParamObject {
  key: string;
  value: string;
}

interface ITrackProductViewedParams {
  name: string;
  url?: string;
  product_id?: string;
  category?: string;
  variant?: string;
}

export const getUtmParamsMap = () => {
  if (isSSR) {
    return [];
  }
  let params: IUtmParamObject[] = [];
  document.cookie
    .split(';')
    .filter(cookie => cookie.includes('utm_'))
    .map(trackingCookie => {
      const [name, value] = trackingCookie.trim().split('=');
      params.push({
        key: name,
        value: value,
      });
    });

  return params;
};

export const trackIdentify = (id: string, options?: object, userData?: IGAUserData) => {
  rudderstackIdentify(id, options);
  if (!isSSR && window.heap) {
    window.heap.identify(id);
  }
  if (!userData) {
    return;
  }
  if (!isSSR && window.fbq) {
    window.fbq({
      em: userData.email,
      fn: userData.firstName,
      ln: userData.lastName,
      ph: userData.phone,
    });
  }
  setUserDataToGtag({
    firstName: userData.firstName,
    lastName: userData.lastName,
    phone: userData.phone,
    email: userData.email,
  });
};

export const trackHeapUserDetails = (email: string, name: string = '') => {
  if (!isSSR && window.heap) {
    window.heap.addUserProperties({ email: email, Name: name });
  }
};

export const trackHeapEvent = (eventName: string) => {
  if (!isSSR && window.heap) {
    window.heap.track(eventName);
  }
};

const isRudderstackTrackingEnabled = () => window.rudderanalytics;

export const trackPageView = (name: string) => {
  if (isSSR) {
    return;
  }
  rudderstackPageEvent(name);
};

export const trackEvent = (
  name: string,
  options: object = {},
  trackingGroups?: string | string[],
  eventId?: string
) => {
  if (isSSR) {
    return;
  }

  trackGAEvent(name, { ...options, session_id: getSessionId() }, trackingGroups);
  sendEventToRudderstack(name, options, eventId);
};

export const trackProductViewed = ({
  name,
  url,
  product_id,
  category,
  variant,
}: ITrackProductViewedParams) => {
  trackEvent('Product Viewed', {
    name,
    url,
    product_id,
    category,
    variant,
  });
  trackFacebook('ViewContent', {
    content_ids: [product_id || ''],
    content_name: product_id,
    content_type: 'product',
    content_category: category,
  });
};

export const trackFormErrors = (form_name: string, errors: any) => {
  if (Object.keys(errors).length > 0) {
    const compiledErrors: any = [];

    Object.entries(errors).map((error: any) => {
      const fieldName = error[0];
      const errorType = error[1].type;

      compiledErrors.push({
        field_name: fieldName,
        error_type: errorType,
      });
    });

    sendEventToRudderstack('Error Form', {
      form_name,
      compiledErrors,
    });
  }
};

export const trackAdalyser = () =>
  (function (clientId: string) {
    if ((window as any)['__adal_disable_' + clientId]) {
      return;
    }
    (function (windowAlias: any, documentAlias: Document, trackerName: string) {
      if (!windowAlias[trackerName]) {
        windowAlias.GlobalAdalyserNamespace = windowAlias.GlobalAdalyserNamespace || [];
        windowAlias.GlobalAdalyserNamespace.push(trackerName);
        windowAlias[trackerName] = function () {
          (windowAlias[trackerName].q = windowAlias[trackerName].q || []).push(arguments);
        };
        windowAlias[trackerName].q = windowAlias[trackerName].q || [];
        var nel = documentAlias.createElement('script'),
          fel = documentAlias.getElementsByTagName('script')[0];
        nel.async = true;
        nel.src = '//c5.adalyser.com/adalyser.js?cid=' + clientId;
        fel.parentNode?.insertBefore(nel, fel);
      }
    })(window, document, 'adalyserTracker');
    window.adalyserTracker('create', {
      campaignCookieTimeout: 15552000,
      conversionCookieTimeout: 604800,
      clientId: clientId,
      trafficSourceInternalReferrers: ['^(.*\\.)?vitl\\.com$'],
    });
  })('vitl');

export const trackAdalyserEvent = (total: number) => {
  if (!isSSR && window.adalyserTracker) {
    window.adalyserTracker('trackEvent', 'lce3', { value: total }, true);
  }
};

export const trackGoogleAdsConv = (
  transactionId: string | number,
  total: number,
  currency: string
) => {
  if (
    !isSSR &&
    window.gtag &&
    formatStringToBoolean(process.env.GATSBY_GOOGLE_ADS_CHECKOUT_CONVERSION_ENABLED)
  ) {
    window.gtag('event', 'conversion', {
      send_to: 'conversion', // sending to conversion group set in gtag config through GTM
      value: total,
      currency: currency,
      transaction_id: transactionId,
    });
  }
};

export const parseAWINCommissionGroup = (input = "") => {
  var output;
  const groups = input.split('|');

  for (let i = 0; i < groups.length; i++) {
      const [group, total] = groups[i].split(':');
      if (group !== 'NEW' && group !== 'DEFAULT')
        return { group, total: parseFloat(total), string: groups[i]};
      if (!output)
        output = { group, total: parseFloat(total), string: groups[i]};
  }

  return output;
}

export const trackConversionAWIN = (
  id: string | number,
  awinTotal: number,
  currency: string,
  discountCode: string,
  awinParts: string
) => {
  if (!isSSR && typeof window.AWIN != 'undefined' && typeof window.AWIN.Tracking != 'undefined') {
    window.AWIN.Tracking.Sale = {};
    window.AWIN.Tracking.Sale.amount = awinTotal;
    window.AWIN.Tracking.Sale.channel = 'aw';
    window.AWIN.Tracking.Sale.orderRef = id;
    window.AWIN.Tracking.Sale.parts = awinParts;
    window.AWIN.Tracking.Sale.currency = currency;
    if (discountCode) {
      window.AWIN.Tracking.Sale.voucher = discountCode;
    }
    window.AWIN.Tracking.Sale.test = Number(
      formatStringToBoolean(process.env.GATSBY_AWIN_TEST_ENABLED)
    );
    window.AWIN.Tracking.run && window.AWIN.Tracking.run();
  }
};

export const trackFacebook = (
  type: string,
  data?: IFacebookData | IPromotionTracking,
  eventId?: string
) => {
  if (!isSSR && window.fbq) {
    if (type in CUSTOM_TRACKING_EVENTS) {
      window.fbq('trackCustom', type, data, { eventID: eventId });
      return;
    }
    window.fbq('track', type, data, { eventID: eventId });
  }
};

export const trackClickSearchButton = () => {
  trackEvent('Search Button Clicked');
};

export const trackClickSearchedProduct = (searchValue: string, link: string) => {
  trackEvent('Search Product Clicked', {
    searchValue,
    link,
  });
};

export const trackReddit = (type: string, data?: IRedditTrackingData | IPromotionTracking) => {
  if (type in CUSTOM_TRACKING_EVENTS) {
    window.rdt('track', 'Custom', data);
  }
  if (!isSSR && window.rdt) {
    window.rdt('track', type, data);
  }
};

export const trackTiktok = (
  type: string,
  data?: ITiktokTrackingData | IPromotionTracking,
  eventId?: string
  ) => {
  if (!isSSR && window.ttq) {
    window.ttq.track(type, { ...data, event_id: eventId });
  }
};

export const trackPinterest = (
  type: string,
  data?: IPinterestTrackingData | IPromotionTracking
) => {
  if (!isSSR && window.pintrk) {
    window.pintrk('track', type, data);
  }
};

export const trackTikTokUserDetails = (email: string, phone: string = '') => {
  if (!isSSR && window.ttq) {
    window.ttq.identify({ email: email, phone_number: phone });
  }
};

export const trackCustomEvent = (eventName: string, data: IPromotionTracking) => {
  trackEvent(eventName, data);
  trackFacebook(eventName, data);
  trackReddit(eventName, data);
  trackTiktok(eventName, data);
  trackPinterest(eventName, data);
};

export const trackVWOEvent = (eventName: string, data: number) => {
  if (!isSSR && window.VWO) {
    // Do not change anything in the following two lines
    window.VWO = window.VWO || [];

    window.VWO.event =
      window.VWO.event ||
      function () {
        window.VWO.push(['event'].concat([].slice.call(arguments)));
      };

    // Replace the property values with your actual values
    window.VWO.event(eventName, {
      revenue: data,
    });
  }
};

export const getEventId = () => uuidv4();
