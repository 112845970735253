import { isSSR } from '@services/global';
import { useLocation } from '@reach/router';

export const isExternalUrl = (url: string) => {
  if (!isSSR) {
    try {
      return window.location.hostname !== new URL(url).hostname;
    } catch {
      return false;
    }
  }
};

export const isAbsoluteUrl = (url: string) => {
  return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
};

export const extractAndRemoveParams = (url: string, params: string[]) => {

  if (isSSR) return { url, extractedParams: {} };
  
  const location = useLocation();
  const isAbsolute = url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
  const urlObj = isAbsolute ? new URL(url) : new URL(url, location.href);

  const extractedParams = params.reduce((acc, param) => {
    const value = urlObj.searchParams.get(param);
    if (value) {
      acc[param] = value;
      urlObj.searchParams.delete(param);
    }
    return acc;
  }, {} as Record<string, string>);
  
  return { url: urlObj.toString(), extractedParams };
}