import * as Sentry from '@sentry/gatsby';

import { GlobalContext } from '@store/global-state';
import { useContext, useState } from 'react';
import { QueryFunctionOptions, useLazyQuery, useQuery } from 'react-apollo';
import { IUserDetailsQueryResponse } from '@src/types/query-response';
import { USER_DETAILS, getUserDetails } from '@src/queries/user';
import { ApolloError } from 'apollo-boost';
import { IUserDetails } from '@src/types/user';
import { client } from '@src/apollo/client';
import { addEmailDataToGTM } from '@src/services/tracking/ga';

const setUserToSentry = (userDetails: IUserDetails) => {
  Sentry.configureScope(scope => {
    if (!userDetails) return;
    scope.setUser({
      id: userDetails?.customerDataPlatformId,
    });
  });
};

const handleNonLoggedIn = (setIsLoggedIn: (state: boolean) => void) => {
  setIsLoggedIn(false);
};

const handleLoggedIn = async (userDetails: IUserDetails, setIsLoggedIn: (state: boolean) => void) => {
  const isLoggedIn = userDetails;
  if (!isLoggedIn) {
    handleNonLoggedIn(setIsLoggedIn);
    return;
  }
  await client.clearStore();
  setIsLoggedIn(true);
  setUserToSentry(userDetails);
  addEmailDataToGTM(userDetails?.email);
};

const useCheckLogInUser = (
  options?: QueryFunctionOptions,
  onLogin?: (userDetails: IUserDetails) => void,
  onErrorCallback?: (error: ApolloError) => void
) => {
  const { setIsLoggedIn, setIsCheckLoginTried } = useContext(GlobalContext);

  return useQuery<IUserDetailsQueryResponse>(USER_DETAILS, {
    ...options,
    onCompleted: data => {
      setIsCheckLoginTried(true);
      handleLoggedIn(data?.user_details, setIsLoggedIn);
      onLogin && onLogin(data?.user_details);
    },
    onError(error) {
      setIsCheckLoginTried(true);
      handleNonLoggedIn(setIsLoggedIn);
      onErrorCallback && onErrorCallback(error);
    },
  });
};

const useCheckLogInAsync = () => {
  const { setIsLoggedIn, setIsCheckLoginTried } = useContext(GlobalContext);
  const [checkingLogin, setCheckingLogin] = useState(true);
  const checkLogInUser = async () => {
    try {
      setCheckingLogin(true);

      const { user_details } = await getUserDetails();
      await handleLoggedIn(user_details, setIsLoggedIn);
      setIsCheckLoginTried(true);
      setCheckingLogin(false);
      return user_details;
    } catch (err) {
      setCheckingLogin(false);
      handleNonLoggedIn(setIsLoggedIn);
      setIsCheckLoginTried(true);
    }
  };
  return { checkLogInUser, checkingLogin, setCheckingLogin };
};
export { useCheckLogInUser, useCheckLogInAsync };
