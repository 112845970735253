import { CUSTOM_TRACKING_EVENTS } from '@src/constants/enums';
import { getSessionId } from '../session';

const isRudderstackTrackingEnabled = () => window.rudderanalytics;

const getRudderstackEventName = (eventName: string) => {
  switch (eventName) {
    case CUSTOM_TRACKING_EVENTS.BasketUpsellClick:
      return 'Promotion Clicked';
    case CUSTOM_TRACKING_EVENTS.BasketUpsellView:
      return 'Promotion Viewed';
    default:
      return eventName;
  }
};

export const sendEventToRudderstack = (
  eventName: string,
  options: object = {},
  eventId?: string
) => {
  if (!isRudderstackTrackingEnabled()) {
    return;
  }

  const mappedEventName = getRudderstackEventName(eventName);

  window.rudderanalytics.track(mappedEventName, {
    ...options,
    session_id: getSessionId(),
    event_id: eventId,
  });
};

export const rudderstackPageEvent = (name: string) => {
  if (!isRudderstackTrackingEnabled()) {
    return;
  }

  window.rudderanalytics.page(name, {
    title: name,
    session_id: getSessionId(),
  });
};

export const rudderstackIdentify = (id: string, options?: object) => {
  if (!isRudderstackTrackingEnabled()) {
    return;
  }

  window.rudderanalytics.identify(id, options);
};
